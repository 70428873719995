import React from 'react'
import Contact from './Contact/Contact'
import Subscribe from './Subscribe/Subscribe'

function Footer() {
  return (
    <footer className="footer">
      <Contact />
      {/* <Subscribe></Subscribe> */}
    </footer>
  )
}

export default Footer
