import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import BurgerMenu from './BurgerMenu/BurgerMenu'
// import FocusController from './FocusController/FocusController';

import '../scss/main.scss'

import '../fonts/subset-BergenSans-Bold.woff2'
import '../fonts/subset-BergenSans-Bold.woff'
import '../fonts/BergenSans-Bold.subset.otf'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }

    /**
     * Side Menu
     * @type {HTMLElement}
     */
    this._menu = null

    /**
     * Close button
     * @type {HTMLElement}
     */
    this._closeButton = null

    /**
     * All nav links.
     * @type {Array<HTMLElement>}
     */
    this._links = []

    this.keepFocusInsideBound = this.keepFocusInside.bind(this)
  }

  componentDidMount() {
    this._menu = document.querySelector('.bm-menu-wrap')
    this._closeButton = this._menu.querySelector('button')
    this._links = this._menu.querySelectorAll('a')

    this.toggleAccessibility()
    this.toggleKeyListener()
  }

  componentDidUpdate() {
    this.toggleAccessibility()
    this.toggleKeyListener()
  }

  /**
   * Handles menu state on open/close.
   *
   * @param {Object} state
   */
  handleMenuState(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  /**
   * Toggles Menu
   *
   */
  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  /**
   * Closes menu.
   *
   */
  closeMenu() {
    this.setState({ menuOpen: false })
  }

  /**
   * Handles links tabindexes inside of menu sidebar on open/close.
   *
   */
  toggleAccessibility() {
    let link
    let tabIndex = this.state.menuOpen ? '0' : '-1'

    for (link of this._links) {
      link.setAttribute('tabindex', tabIndex)
    }

    this._closeButton.setAttribute('tabindex', tabIndex)
  }

  /**
   * Toggle Event listener on Menu open/close.
   *
   */
  toggleKeyListener() {
    if (this.state.menuOpen) {
      this._menu.addEventListener('keydown', this.keepFocusInsideBound)
    } else {
      this._menu.removeEventListener('keydown', this.keepFocusInsideBound)
    }
  }

  /**
   * Keep focus inside of menu bar if open.
   *
   * @param {Object} event Event object.
   */
  keepFocusInside(event) {
    let keyCode = event.which || event.keyCode
    let lastItem = this._links[this._links.length - 1]

    // If TAB is used.
    if (keyCode === 9) {
      // Move focus to first element that can be tabbed if Shift isn't used
      if (event.target === lastItem && !event.shiftKey) {
        event.preventDefault()
        this._closeButton.focus()

        // Move focus to last element that can be tabbed if Shift is used
      } else if (event.target === this._links[0] && event.shiftKey) {
        event.preventDefault()
        this._closeButton.focus()
      } else if (event.target === this._closeButton && !event.shiftKey) {
        this._links[0].focus()
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header
              toggleMenu={() => this.toggleMenu()}
              isHomePage={this.props.homePage}
            />
            <BurgerMenu
              isOpen={this.state.menuOpen}
              onStateChange={state => this.handleMenuState(state)}
              close={() => this.closeMenu()}
            />
            <main className="main">{this.props.children}</main>
            <Footer />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// new FocusController().init();
