import React, { Component } from 'react'
import { Link } from 'gatsby'
import { stack as Menu } from 'react-burger-menu'

class BurgerMenu extends Component {
  render() {
    return (
      <Menu
        customBurgerIcon={false}
        right
        isOpen={this.props.isOpen}
        onStateChange={state => this.props.onStateChange(state)}
        width={320}
        bodyClassName={'no-scroll'}
      >
        <Link
          id="home"
          className="menu-item text--4"
          to="/"
          activeClassName="menu-item--active"
          onClick={this.props.close}
        >
          Home
        </Link>
        <Link
          id="about-us"
          className="menu-item text--4"
          to="/about"
          activeClassName="menu-item--active"
          onClick={this.props.close}
        >
          Get to know us
        </Link>
        <Link
          id="work"
          className="menu-item text--4"
          to="/our-work"
          activeClassName="menu-item--active"
          onClick={this.props.close}
          partiallyActive={true}
        >
          Our Work
        </Link>
        <Link
          id="developers"
          className="menu-item text--4"
          to="/services/hire-developers"
          activeClassName="menu-item--active"
          onClick={this.props.close}
        >
          Hire Developers
        </Link>
        <Link
          id="ai"
          className="menu-item text--4"
          to="/services/ai"
          activeClassName="menu-item--active"
          onClick={this.props.close}
        >
          AI Apps Development
        </Link>
        <Link
          id="services"
          className="menu-item text--4"
          to="/services"
          activeClassName="menu-item--active"
          onClick={this.props.close}
        >
          Services
        </Link>
        {/* <Link id="vr"
           className="menu-item text--4"
           to="/360"
           activeClassName="menu-item--active"
           onClick={this.props.close}
        >
          360 Virtual Tour
        </Link> */}
        <a
          id="careers"
          className="menu-item text--4"
          href="https://careers.emersoft.co/"
          onClick={this.props.close}
          target="_blank"
          rel="noreferrer"
        >
          Careers
        </a>
      </Menu>
    )
  }
}

export default BurgerMenu
