import React from 'react'
import { Link } from 'gatsby'

function Contact() {
  return (
    <section className="footer__contact">
      <div className="container">
        <h2
          className="footer__heading
                    text--3
                    underline"
        >
          Let's talk
        </h2>

        <ul className="footer__contact-list">
          <li className="footer__contact-item footer__contact-item--wide">
            <div className="footer__contact-company-wrapper">
              <div className="footer__contact-company-item">
                <h3 className="footer__contact-item-heading text--6">
                  EMERSOFT LTD
                </h3>
                <p className="footer__contact-paragraph text--6 mt-b-20">
                  Company number 10977747
                </p>
                <a
                  href="mailto:hello@emersoft.co"
                  className="footer__contact-link text--6"
                >
                  hello@emersoft.co
                </a>
                <br />
                <Link to="/privacy-policy" className="footer__contact-link text--6">
                  Privacy Policy
                </Link>
              </div>
              <div className="footer__contact-company-item">
                <h3 className="footer__contact-item-heading text--6 mt-b-20">
                  EMERSOFT LLC
                </h3>
                <Link to="/privacy-policy-usa" className="footer__contact-link text--6">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </li>
          <li className="footer__contact-item">
            <h3 className="footer__contact-item-heading text--6">London</h3>
            <address className="footer__contact-address">
              <p className="footer__contact-paragraph text--6">
                71 Shelton Street, <br />
                London, WC2H 9JQ, <br />
                United Kingdom
              </p>
            </address>
          </li>
          <li className="footer__contact-item">
            <h3 className="footer__contact-item-heading text--6">Las Vegas</h3>
            <address className="footer__contact-address">
              <p className="footer__contact-paragraph text--6">
                9620 Las Vegas Blvd S, <br />
                STE E4 #612, <br />
                Las Vegas, NV 89123,<br />
                United States
              </p>
            </address>
          </li>
        </ul>
        <div className="footer__info">
          <ul className="footer__social-list">
            <li className="footer__social-item">
              <a
                href="https://x.com/emersoftai"
                className="footer__social-link
                          footer__social-link--twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none">
                  <path fill="currentColor"
                        d="M17.244.25h3.308l-7.227 8.26 8.502 11.24H15.17l-5.214-6.817L3.99 19.75H.68l7.73-8.835L.254.25H7.08l4.713 6.231L17.243.25Zm-1.161 17.52h1.833L6.084 2.126H4.117L16.083 17.77Z" />
                  <title>Twitter</title>
                </svg>
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.facebook.com/emersoft"
                className="footer__social-link
                              footer__social-link--facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="25"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Facebook</title>
                  <g fill="none" fillRule="evenodd">
                    <g id="facebook" fill="currentColor" fillRule="nonzero">
                      <path
                        d="M21.62 0H3.367A3.367 3.367 0 0 0 0 3.367V21.62a3.367 3.367 0 0 0 3.367 3.367h9.002l.016-8.93h-2.32a.547.547 0 0 1-.547-.544l-.012-2.878a.547.547 0 0 1 .548-.55h2.315V9.304c0-3.227 1.971-4.985 4.85-4.985h2.363c.302 0 .547.245.547.548v2.427a.547.547 0 0 1-.547.547h-1.45c-1.565 0-1.868.745-1.868 1.837v2.407h3.44c.328 0 .582.286.543.612l-.34 2.878a.547.547 0 0 1-.544.483h-3.084l-.015 8.929h5.356a3.367 3.367 0 0 0 3.367-3.367V3.367A3.367 3.367 0 0 0 21.62 0z" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.linkedin.com/company/emersoft/"
                className="footer__social-link
                          footer__social-link--linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="25"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn</title>
                  <g fill="none" fillRule="evenodd">
                    <g id="linkedin" fill="currentColor" fillRule="nonzero">
                      <path
                        d="M22.739 0H2.26A2.261 2.261 0 0 0 0 2.261v20.477A2.261 2.261 0 0 0 2.261 25h20.477A2.261 2.261 0 0 0 25 22.738V2.261A2.261 2.261 0 0 0 22.739 0zM7.736 21.587a.658.658 0 0 1-.658.658H4.277a.658.658 0 0 1-.659-.658V9.843c0-.363.295-.658.659-.658h2.8c.364 0 .66.295.66.658v11.744zM5.677 8.078a2.661 2.661 0 1 1 0-5.323 2.661 2.661 0 0 1 0 5.323zm16.7 13.562c0 .334-.272.605-.606.605h-3.006a.605.605 0 0 1-.605-.605V16.13c0-.821.241-3.6-2.148-3.6-1.852 0-2.228 1.902-2.303 2.755v6.354c0 .334-.271.605-.606.605h-2.907a.605.605 0 0 1-.605-.605V9.79c0-.334.27-.605.605-.605h2.907c.335 0 .606.271.606.605v1.025c.687-1.031 1.707-1.827 3.881-1.827 4.814 0 4.786 4.497 4.786 6.968v5.684z" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.youtube.com/c/emersoft"
                className="footer__social-link
                          footer__social-link--youtube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="36"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Youtube</title>
                  <g fill="none" fillRule="evenodd">
                    <g id="youtube" fill="currentColor" fillRule="nonzero">
                      <path
                        d="M27.925.03H7.32A7.32 7.32 0 0 0 0 7.35v10.3a7.32 7.32 0 0 0 7.32 7.32h20.605a7.32 7.32 0 0 0 7.32-7.32V7.35a7.32 7.32 0 0 0-7.32-7.32zm-4.95 12.971l-9.638 4.597a.387.387 0 0 1-.554-.35v-9.48c0-.288.305-.475.562-.345l9.638 4.884a.387.387 0 0 1-.009.694z" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://instagram.com/emersoftai"
                className="footer__social-link
                          footer__social-link--instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="25" height="25" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <title>Instagram</title>
                  <g>
                    <g>
                      <path
                        fill="currentColor"
                        d="M256.052,138.088c-65.268,0-117.913,52.646-117.913,117.912c0,65.267,52.645,117.91,117.913,117.91 c65.267,0,117.911-52.644,117.911-117.91C373.963,190.734,321.318,138.088,256.052,138.088z M256.052,332.659    c-42.177,0-76.659-34.38-76.659-76.659c0-42.279,34.378-76.657,76.659-76.657c42.277,0,76.657,34.378,76.657,76.657    C332.709,298.279,298.229,332.659,256.052,332.659L256.052,332.659z M406.288,133.266c0,15.291-12.315,27.502-27.502,27.502    c-15.292,0-27.502-12.314-27.502-27.502c0-15.188,12.313-27.503,27.502-27.503C393.973,105.763,406.288,118.077,406.288,133.266z     M484.382,161.177c-1.743-36.839-10.158-69.474-37.148-96.36c-26.886-26.887-59.52-35.302-96.36-37.148    c-37.971-2.155-151.777-2.155-189.747,0c-36.739,1.743-69.372,10.159-96.361,37.046c-26.99,26.887-35.302,59.52-37.149,96.36    c-2.155,37.969-2.155,151.777,0,189.747c1.745,36.842,10.159,69.473,37.149,96.361c26.989,26.887,59.521,35.301,96.361,37.148    c37.969,2.154,151.776,2.154,189.747,0c36.841-1.744,69.475-10.161,96.36-37.148c26.887-26.889,35.302-59.52,37.148-96.361    C486.538,312.853,486.538,199.147,484.382,161.177z M435.329,391.563c-8.004,20.112-23.5,35.61-43.716,43.716    c-30.273,12.009-102.108,9.236-135.562,9.236c-33.456,0-105.393,2.669-135.563-9.236c-20.114-8.005-35.611-23.498-43.717-43.716    C64.766,361.29,67.538,289.455,67.538,256s-2.669-105.391,9.234-135.562c8.006-20.114,23.5-35.61,43.717-43.716    c30.273-12.007,102.107-9.236,135.563-9.236c33.453,0,105.391-2.668,135.562,9.236c20.113,8.002,35.609,23.5,43.716,43.716 c12.007,30.272,9.237,102.107,9.237,135.562S447.336,361.392,435.329,391.563z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
          <ul className="footer__badges-list">
            <li className="footer__badges-list-item footer__badges-list-item--hubspot">
              <a
                href="https://app.hubspot.com/academy/achievements/bfjq1p4h/en/1/pawel-marciniuk/hubspot-solutions-partner"
                title="HubSpot Solutions Partner"
                target="_blank"
                className="footer__badges-link"
              >
                <img
                  src="https://cdn.emersoft.co/emersoft-website/homepage/emerosft-hubspot-badge.png"
                  alt="HubSpot Solutions Partner" />
              </a>
            </li>
            <li className="footer__badges-list-item footer__badges-list-item--salesforce">
              <img
                src="https://cdn.emersoft.co/emersoft-website/homepage/Salesforce_Partner_Badge_RGB.png"
                alt="Salesforce Partner" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Contact
